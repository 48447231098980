<template>
  <v-row
    class="pricing-container page-container mx-auto"
  >
    <v-col
      v-for="(item, i) in plans"
      :key="i"
      lg="6"
      xl="4"
      cols="12"
    >
      <div
        class="pricing-container__item"
      >
        <header :class="item.color">
          <h2 class="font-weight-regular mb-1 plan-title">
            {{ item.title }}
          </h2>
          <hr>
          <p class="mb-0 white--text font-weight-bold"
             style="font-size: 50px"
          >
            {{ item.price }}
          </p>
          <p class="white--text">
            {{ item.quantityOfUsers }}
          </p>
          <h2
            class="white--text"
            v-html="item.featuresTitle"
          />
        </header>
        <main>
          <ul v-if="Array.isArray(item.text)">
            <li
              v-for="(textItem, t) in item.text"
              :key="i + t"
            >
              <v-icon color="black">
                mdi-check
              </v-icon>
              {{ textItem }}
            </li>
          </ul>
        </main>
        <v-row justify="center" align="end" no-gutters>
          <v-btn height="70" width="380">
            More Information
          </v-btn>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'PricingPlans',
  data() {
    return {
      plans: [
        {
          title: 'Basic',
          color: 'lightAccent',
          price: '$$/mo',
          quantityOfUsers: 'with 15 users',
          text: [
            '1 Project',
            'Export to PDF, Word',
            'Pause project - 1mth',
            'Etendering',
          ],
        },
        {
          title: 'Pro',
          color: 'accent',
          price: '$$$/mo',
          quantityOfUsers: 'with 25 users',
          text: [
            'Same as BASIC, extended to 10 Projects',
            'Add on projects',
            'Customisable workflows',
            'Create and share Projects',
            '1 scope of work document included',
          ],
        },
        {
          title: 'Enterprise',
          color: 'primary',
          featuresTitle: `Contact for custom <br> pricing`,
          text: [
            'Same as PRO, extended to 25 Projects',
            '3 scope of work documents included',
            'Personalised support',
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.pricing-container {
  justify-content: space-between;
  max-width: 1650px;
  margin-top: -312px !important;
  margin-bottom: 50px;
  position: relative;
  @media only screen and (max-width: 903px)  {
    justify-content: center;
  }
  &__item {
    box-shadow: 0px 0px 5px rgb(33 17 17 / 35%);
    padding-bottom: 48px;
    min-height: 750px;
    max-width: 700px;
    display: flex;
    margin: 0 auto 32px auto;
    flex-direction: column;
    ::v-deep .v-btn {
      background: #add3f180;
      border: none;
      box-shadow: none;
      &__content {
        text-transform: capitalize;
        color: var(--v-primary-base);
        font-size: 28px;
      }
    }
    header {
      padding: 16px 0;
      height: 200px;
      min-height: 200px;
      hr {
        max-width: 150px;
        margin: auto;
        margin-top: 24px;
        height: 2px;
        background: #fff;
        border: #fff;
      }
      .plan-title {
        font-size: 34px;
        margin-bottom: 10px;
        line-height: normal;
        color: #fff;
        text-transform: uppercase;
      }
      h4 span {
        width: 220px;
        margin: auto;
        display: flex;
      }
    }
    main {
      padding: 15px 45px 32px 45px;
      text-align: left;
      p {
        margin: auto;
      }
      ul {
        padding-left: 20px;
        li {
          font-weight: bold;
          font-size: 28px;
          margin-top: 12px;
          display: flex;
          align-items: flex-start;
        }
        list-style: none;
        .v-icon {
          margin: 8px 8px 0 0;
          &:before {
            font-weight: bold;
          }
        }
      }
    }
  }
}

</style>
