<template>
  <div
    class="py-12"
    style="background: #eeeeee"
  >
    <div class="page-container" style="max-width: 1000px">
      <h2 class="mb-12" style="font-size: 40px">
        Increase users
      </h2>
      <v-slider
        v-model="slider"
        thumb-label="always"
        color="mainGrey"
        track-color="lightGrey"
        thumb-size="50"
        min="1"
        background="#eeeeee"
      />
      <h2 style="font-size: 60px" class="accent--text mt-12">
        $$
      </h2>
      <p style="sont-size: 28px" class="lightBlack--text">
        $ / per month
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PricingRange',
  data() {
    return {
      slider: 1,
    };
  },
};
</script>

<style lang="scss" scoped>
  .v-slider {
    ::v-deep &__track-container {
      height: 10px;
    }
    ::v-deep &__thumb-label {
      border-radius: 50%;
      position: relative;
      background: #fff !important;
      border: 1px solid var(--v-primary-base) !important;
      color: #000;
      &::after {
        content: "user(s)";
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: rotate(-45deg);
        margin-bottom: -15px;
      }
    }
  }
</style>
